.box_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form_item{
    width: 320pt;
    min-height: 160pt;
    max-height: 380pt;
}

.form_box_item{
    width: 300pt;
}

.form_data_item {
    padding-right: 5pt;
}

.form_print_button {
    margin: 5pt;
    height: 30pt;
    white-space: nowrap;
    padding: 15pt 5pt;
}

.modal_item{
    width: 440pt;
}

.verification_block{
    display: flex;
    justify-content: center;
    align-items: center;
}

.verification_message{
    font-size: 17px;
    background-color: #f0f0f0;
    padding: 2px;
    border-radius: 5px;
    margin: 5px 0 5px 0;
}

.box_device_name{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 7px;
    margin: 5px 0 5px 0;
}

.response{
    display: flex;
    justify-content: center;
    margin: 5px auto;
    width: 100%;
}

.note{
    display: flex;
    justify-content: center;
    color: #a0a0a0;
    margin: 5px 0 0 0;
}

.submit_button{
    display: flex;
    justify-content: center;
}

#error_alert_popup{
    font-size: 15pt;
    height: 50pt;
    background-color: #d32f2f;
    color: white;
}

#success_alert_popup{
    font-size: 15pt;
    height: 50pt;
    background-color: #2e7d32;
    color: white
}

#verification_button {
    margin: 15px 100px;
}

.alert_icon_section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5pt;
  width: 40pt;
  height: 40pt;
}

.alert_content {
  display: flex;
  align-items: center;
  text-align: center;
}

.alert_icon {
  width: 100%;
  height: 100%;
}
